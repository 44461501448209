
import { Component, Vue } from 'vue-property-decorator'
import { ApprovalList } from '@/types/assetScheduling'
import AddApproval from '@/views/assetScheduling/setting/AddApproval.vue'
@Component({
  name: 'Approval',
  components: { AddApproval }
})
export default class Approval extends Vue {
  private showDialog = false
  private tableData: ApprovalList[] =[]
  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private id = ''
  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.assetScheduling.selectAssetConfLogByList, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onEdit (id: string) {
    this.id = id
    this.showDialog = true
  }

  success () {
    this.id = ''
    this.getData()
  }
}
