
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
@Component
export default class HandoverMatterAdd extends Vue {
    @Prop({ default: false }) showDialog!: boolean
    @Prop({ default: '' }) private id!: string
    private formInfo = this.formInfoBak
    private projectList = []
    private roleList = []
    private personList = []
    private list = ['按姓名搜索', '按角色搜索', '按项目搜索']
    private props = {
      children: 'childNode',
      label: 'name',
      value: 'id'
    }

    searchInfo = 0
    searchInfo1 = 0
    get formInfoBak () {
      return {
        person1: '资产保管员',
        person: '发起人',
        putUserId: [],
        outUserId: [],
        rightUserId: []
      }
    }

    private rules = {
      putUserId: [
        { required: true, message: '请选择调入方主管', trigger: ['blur', 'change'] }
      ],
      outUserId: [
        { required: true, message: '请选择调出方主管', trigger: ['blur', 'change'] }
      ],
      rightUserId: [
        { required: true, message: '请选择处置审核人', trigger: ['blur', 'change'] }
      ]
    }

    private submitShow = false

    @Watch('id')
    getId (val: string) {
      val && this.getDetail(val)
    }

    created () {
      this.getProject()
      this.getRole()
      this.getPerson()
    }

    // 查询项目下人员
    getProject () {
      this.$axios.get(this.$apis.assetScheduling.selectProjectUserByProjectList).then(res => {
        this.projectList = res || []
      })
    }

    // 查询角色下人员
    getRole () {
      this.$axios.get(this.$apis.assetScheduling.selectRoleUserByRoleList).then(res => {
        this.roleList = res || []
      })
    }

    // 查询人员列表
    getPerson () {
      this.$axios.get(this.$apis.assetScheduling.selectUserListByList).then(res => {
        this.personList = res.list || []
      })
    }

    getDetail (val: string) {
      this.$axios.get(this.$apis.assetScheduling.selectAssetConfLogByConfType, { confType: val }).then(res => {
        this.formInfo = res || []
        this.formInfo.person = '发起人'
        this.formInfo.person1 = '资产保管员'
      })
    }

    onSubmit () {
      (this.$refs.formInfo as ElForm).validate(valid => {
        if (valid) {
          this.submitShow = true
          const params = this.id === '1' ? {
            outUserId: this.formInfo.outUserId,
            putUserId: this.formInfo.putUserId
          } : this.id === '2' ? {
            rightUserId: this.formInfo.rightUserId
          } : {}
          this.$axios.post(this.$apis.assetScheduling.insertAssetConfLog, {
            ...params,
            confType: this.id
          }).then(() => {
            this.$message.success('保存成功')
            this.closeDialog()
            this.$emit('success')
          }).finally(() => {
            this.submitShow = false
          })
        }
      })
    }

    closeDialog () {
      this.formInfo = this.formInfoBak
      this.$nextTick(() => {
        (this.$refs.formInfo as ElForm).clearValidate()
      })
      this.$emit('update:showDialog', false)
      this.$emit('success')
    }
}
